<template>
  <div>
    <transition name="fade-three" mode="out-in">
      <router-view v-if="show" />
    </transition>
    <van-tabbar v-show="isShowBottom" v-model="active" @change="ontabbar" z-index='1000' inactive-color="#999999"
      active-color="#FF850D" safe-area-inset-bottom :border="false">
      <van-tabbar-item replace :to="item.path" :name="item.id" v-for="item in tabBarArr" class="tab-item"
        :class="{ bigItem: item.routeName == $route.name }" :key="item.id">
        <template #icon="props">
          <img :src="item.selected" v-if="props.active" />
          <img :src="item.normal" v-if="!props.active" />
        </template>
        {{ item.name }}
      </van-tabbar-item>
    </van-tabbar>


  </div>
</template>

<script>
import diffusion from "@/mixin/diffusion.js"
import is_tabbar from "../mixin/is_tabbar"
export default {
  name: "tabbar",
  mixins: [diffusion, is_tabbar],
  data() {
    return {
      show: true,
      tabBarArr: [
        {
          id: 0, //ID号
          name: this.$t("首页"), //名称
          path: "/",
          routeName: "/", //路由名称
          normal: require("@/assets/img/tabbar_home.png"), //未选中时图标
          selected: require("@/assets/img/tabbar_home_select.png"), //选中时图标
        },
        {
          id: 1, //ID号
          name: this.$t("抢购区"), //名称
          path: "/rushToPurchase",
          routeName: "rushToPurchase", //路由名称
          normal: require("@/assets/img/tabbar_rush_to_buy.png"), //未选中时图标
          selected: require("@/assets/img/tabbar_rush_to_buy_select.png"), //选中时图标
        },
        {
          id: 2, //ID号
          name: this.$t("转卖区"), //名称
          path: "/resell",
          routeName: "resell", //路由名称
          normal: require("@/assets/img/tabbar_resell.png"), //未选中时图标
          selected: require("@/assets/img/tabbar_resell_select.png"), //选中时图标
        },
        {
          id: 3, //ID号
          name: this.$t("订单"), //名称
          path: "/order",
          routeName: "order", //路由名称
          normal: require("@/assets/img/tabbar_order.png"), //未选中时图标
          selected: require("@/assets/img/tabbar_order_select.png"), //选中时图标
        },
        {
          id: 4, //ID号
          name: this.$t("我的"), //名称
          path: "/mine",
          routeName: "mine", //路由名称
          normal: require("@/assets/img/tabbar_mine.png"), //未选中时图标
          selected: require("@/assets/img/tabbar_mine_select.png"), //选中时图标
        },
      ],
      isShowBottom: true, //显示或者隐藏footer
      documentHeight: document.documentElement.clientHeight, //默认屏幕高度
    }
  },

  computed: {
    active: {
      get: function () {
        let obj = this.tabBarArr.find(
          (val) => val.routeName == this.$route.name
        )
        return obj.id || 0
      },
      set: function (data) { },
    },
  },
  methods: {
    switchTo(routeName) {
      if (this.$route.name === routeName) return
      this.$router.replace(routeName)
    },
    ontabbar() {
      if (!localStorage.getItem("token")) {
        // this.$toast()
        localStorage.removeItem("token")
        setTimeout(() => {
          this.$router.replace("/login")
        }, 600)
      }
    },
  },
  mounted() {
    if (this.$refs.btn) {
      this.$refs.btn.map((item) => {
        item.addEventListener("touchstart", (e) => {
          this.diffusion(item, e.touches[0].clientX, e.touches[0].clientY)
        })
      })
    }
    // window.onresize监听页面高度的变化
    window.onresize = () => {
      return (() => {
        if (this.documentHeight > document.documentElement.clientHeight) {
          this.isShowBottom = false
        } else {
          this.isShowBottom = true
        }
      })()
    }
  },
}
</script>

<style lang="scss" scoped>
.fade-three-enter-active,
.fade-three-leave-active {
  transition: opacity 0.15s;
}

.fade-three-enter,
.fade-three-leave-to

/* .fade-three-leave-active below version 2.1.8 */
  {
  opacity: 0;
}

.footBox {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1000;

  .navFoot {
    width: 100%;
    background: rgba(255, 255, 255, 1);
    display: flex;
    flex-direction: row;
    align-items: center;

    .tab-item {
      position: relative;
      flex: 1;
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 8vh;
      align-items: center;

      >img {
        width: 7vw;
        height: 7vw;
      }

      >span {
        font-size: 3.2vw;
        font-weight: 400;
        color: #999999;
      }

      .on {
        color: $theme;
      }
    }

    .bigItem {
      z-index: +1;

      >img {
        animation: animateImg 0.9s ease-in-out;
      }

      @keyframes animateImg {
        0% {
          transform: translate3d(0, 0, 0);
        }

        25% {
          transform: translate3d(0, -40%, 0);
        }

        75% {
          transform: translate3d(0, 15%, 0);
        }

        100% {
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }
}

// 判断iphoneX 将 footerBox 的 padding-bottom 填充到最底部
@supports (bottom: env(safe-area-inset-bottom)) {
  .footBox {
    padding-bottom: env(safe-area-inset-bottom);
  }
}

@supports (bottom: constant(safe-area-inset-bottom)) {
  .footBox {
    padding-bottom: constant(safe-area-inset-bottom);
  }
}

// vant tabber
/deep/.van-tabbar {
  height: 8vh;

  .van-tabbar-item__icon>img {
    width: 7vw;
    height: 7vw;
  }
}
</style>